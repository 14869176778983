import { useState } from "react";
import { useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as React from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRobotDirectionbyMapbox, robotmsgAction, waypointcolor, waypointlatitude, waypointlongitude } from "../redux/Actions";
import { getFleetListUser, waypointslist } from "../API";
import { Switch, styled } from "@mui/material";
import { FiNavigation2 } from "react-icons/fi";
import { waypointimage } from "../Data/MapData";

let origin;
let destination;
mapboxgl.accessToken =
  "pk.eyJ1IjoibXJsYWtzaHlhZ3VwdGEiLCJhIjoiY2xpZWZlcTJjMGo4ZDNkczBwc2ZmcW1qMyJ9.Kurk7BS04invJcCwS0m4Pg";

const checkActionId = (commandListData, props) => {
  if (
    !localStorage.getItem("actionId") ||
    localStorage.getItem("actionId") === "null"
  ) {
    localStorage.setItem(
      "actionId",
      JSON.stringify({
        actionType: commandListData.actionType,
        actionId: commandListData.actionId,
      })
    );

    return true;
  } else {
    if (
      commandListData.actionId !==
      JSON.parse(localStorage.getItem("actionId")).actionId
    ) {
      localStorage.setItem(
        "actionId",
        JSON.stringify({
          actionType: commandListData.actionType,
          actionId: commandListData.actionId,
        })
      );
      return true;
    } else {
      return false;
    }
  }
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 16,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#000",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark"
            ? "rgb(189,196,224)"
            : "rgb(189,196,224)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 12,
    height: 12,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor:
      theme.palette.mode === "light" ? "#000" : "rgb(189,196,224)",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    color: "rgb(189,196,224)",
    border: "2px solid rgb(189,196,224)",
  },
}));

function isPointInCircle(pointLat, pointLng, centerLat, centerLng, radius) {
  var ky = 40000 / 360;
  var kx = Math.cos((Math.PI * centerLat) / 180.0) * ky;
  var dx = Math.abs(centerLng - pointLng) * kx;
  var dy = Math.abs(centerLat - pointLat) * ky;

  return Math.sqrt(dx * dx + dy * dy);
}
const MapboxMain = (props) => {
  const robotDetails = useSelector((state) => state.robotDetailsReducer);
  const { robotmsgData } = useSelector((state) => state.robotMessageReducer);
  const token = localStorage.getItem('token')
  const [map, setMap] = useState(null);
  const [robotMarker, setRobotMarker] = useState(null);
  const [destinationMarker, setDestinationMarker] = useState(null);
  const [userControllingMap, setUserControllingMap] = useState(false);
  const [, setPolygonarray] = useState([]);
  const newMapRef = useRef(null)
  
  const [nogozonescoordinates, setnogozonescoordinates] = useState(
    props.nogozones.map((data) => [data.lng, data.lat])
  );
  const [geofencecoordinates, setgeofencecoordinates] = useState(
    props.geofencezone.map((data) => [data.lng, data.lat])
  );

  const [slamPosition, setSlamPosition] = useState(
    robotDetails[props.robotId]?.latitudeSlam &&
      robotDetails[props.robotId]?.longitudeSlam
      ? true
      : false
  );
  const [zoom, setZoom] = useState(15);
  const { robotDirections } = useSelector(
    (state) => state.robotDirectionsReducer
  );

  const mapRef = useRef(null);

  let destinationMarkerForBound = [];

  const dispatch = useDispatch();

  const robotMarkerDiv = document.createElement("div");
  robotMarkerDiv.style.width = "35px";
  robotMarkerDiv.style.height = "35px";
  // robotMarkerDiv.style.backgroundImage = "url(/assets/images/robot-icon-2.svg)";
  robotMarkerDiv.style.borderRadius = "100%";
  robotMarkerDiv.style.animation = "example 1s ease-in-out infinite";
  // robotMarkerDiv.style.transform = "rotate(180deg)";
  // robotMarkerDiv.style.border = "3px solid #fff";

  const destinationMarkerDiv = document.createElement("div");
  destinationMarkerDiv.style.width = "35px";
  destinationMarkerDiv.style.height = "35px";
  // destinationMarkerDiv.style.visibility = "hidden";
  destinationMarkerDiv.style.backgroundImage =
    "url(/assets/images/dropoff.svg)";
  destinationMarkerDiv.style.borderRadius = "100%";
  // destinationMarkerDiv.style.animation = "example 1s ease-in-out infinite";

  const updateRobotpath = () => {
    const map = mapRef.current;
    if (!props.robotpathcheck) {
      if (
        robotDetails[props.robotId].hasOwnProperty("path") &&
        robotDetails[props.robotId].path.length > 0 &&
        robotDetails[props.robotId].hasOwnProperty("robotCommandList") &&
        robotDetails[props.robotId].robotCommandList.length > 0 &&
        robotDetails[props.robotId].robotCommandList
        .find((x) => x.actionPriority === 1)&&
        robotDetails[props.robotId].robotCommandList.find(
          (x) => x.actionPriority === 1
        )?.actionType === "MOVELOCATION" &&
        map.isStyleLoaded()
      ) {
        const newrobotPath = robotDetails[props.robotId].path.map(
          (pathDirection) => [pathDirection.lng, pathDirection.lat]
        );

        const mySource = map.getSource("robotPath");
        map &&
          mySource &&
          mySource.setData({
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: newrobotPath,
            },
          });

        if (!userControllingMap) {
          // Create a 'LngLatBounds' with both corners at the first coordinate.
          const bounds = new mapboxgl.LngLatBounds(
            newrobotPath[0],
            newrobotPath[0]
          );

          // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
          for (const coord of newrobotPath) {
            bounds.extend(coord);
          }

          bounds.extend([
            slamPosition
              ? parseFloat(robotDetails[props.robotId].longitudeSlam)
              : parseFloat(robotDetails[props.robotId].longitude),
            slamPosition
              ? parseFloat(robotDetails[props.robotId].latitudeSlam)
              : parseFloat(robotDetails[props.robotId].latitude),
          ]);

          if (destinationMarkerForBound.length !== 0) {
            bounds.extend(destinationMarkerForBound);
          }

          map.fitBounds(bounds, {
            padding: 100,
          });
        }

        map.getLayer("path") &&
          map.setPaintProperty("path", "line-color", "#0f0");
      } else {
        const mySource = map.getSource("robotPath");
        map &&
          mySource &&
          mySource.setData({
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: [],
            },
          });
      }
    } else {
      if (
        robotDetails[props.robotId].hasOwnProperty("robotCommandList") &&
        robotDetails[props.robotId].robotCommandList.length > 0 &&
        robotDetails[props.robotId].robotCommandList
        .find((x) => x.actionPriority === 1)&&
        robotDetails[props.robotId].robotCommandList.find(
          (x) => x.actionPriority === 1
        ).actionType === "MOVELOCATION" &&
        map.isStyleLoaded() &&
        checkActionId(
          robotDetails[props.robotId].robotCommandList.find(
            (x) => x.actionPriority === 1
          ),
          props
        )
      ) {
        origin = {
          lat: robotDetails[props.robotId].robotCommandList.find(
            (x) => x.actionPriority === 1
          ).latitude,
          lng: robotDetails[props.robotId].robotCommandList.find(
            (x) => x.actionPriority === 1
          ).longitude,
        };
        destination = {
          lat: props.robotlatitude,
          lng: props.robotlongitude,
        };

        dispatch(getRobotDirectionbyMapbox(origin, destination));
      }

      if (robotDirections.hasOwnProperty("routes")) {
        const mapboxPath = robotDirections.routes[0].geometry.coordinates;

        let tempMapBoxPath = [...mapboxPath];
        let nearest = tempMapBoxPath.length - 1;
        tempMapBoxPath.forEach((path, index) => {
          if (
            isPointInCircle(
              path[1],
              path[0],
              parseFloat(robotDetails[props.robotId].latitude),
              parseFloat(robotDetails[props.robotId].longitude),
              0.01
            ) <
            isPointInCircle(
              tempMapBoxPath[nearest][1],
              tempMapBoxPath[nearest][0],
              parseFloat(robotDetails[props.robotId].latitude),
              parseFloat(robotDetails[props.robotId].longitude),
              0.01
            )
          ) {
            nearest = index;
          }
        });

        tempMapBoxPath.splice(nearest);

        const mySource = map.getSource("robotPath");

        map &&
          mySource &&
          mySource.setData({
            type: "FeatureCollection",
            name: "robotPaths",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "LineString",
                  coordinates: tempMapBoxPath,
                },
              },
              {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: [
                    [
                      parseFloat(robotDetails[props.robotId]?.longitude),
                      parseFloat(robotDetails[props.robotId]?.latitude),
                    ],
                    [
                      tempMapBoxPath.length &&
                        tempMapBoxPath[tempMapBoxPath.length - 1][0],
                      tempMapBoxPath.length &&
                        tempMapBoxPath[tempMapBoxPath.length - 1][1],
                    ],
                  ],
                },
              },
            ],
          });

        map.getLayer("path") &&
          map.setPaintProperty("path", "line-color", "#A020F0");

        if (!userControllingMap) {
          // Create a 'LngLatBounds' with both corners at the first coordinate.
          const bounds = new mapboxgl.LngLatBounds(
            tempMapBoxPath[0],
            tempMapBoxPath[0]
          );

          // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
          for (const coord of tempMapBoxPath) {
            bounds.extend(coord);
          }

          bounds.extend([
            slamPosition
              ? parseFloat(robotDetails[props.robotId].longitudeSlam)
              : parseFloat(robotDetails[props.robotId].longitude),
            slamPosition
              ? parseFloat(robotDetails[props.robotId].latitudeSlam)
              : parseFloat(robotDetails[props.robotId].latitude),
          ]);

          map.fitBounds(bounds, {
            padding: 100,
          });
        }
      }
    }
  };

  useEffect(() => {
    
    const initializeMap = () => {
       const newMap = new mapboxgl.Map({
        container: "map-container-id",
        style: "mapbox://styles/mrlakshyagupta/climr57of00le01pgc0jjgfoh",
        center: [
          slamPosition
            ? robotDetails[props.robotId].longitudeSlam
            : robotDetails[props.robotId].longitude,
          slamPosition
            ? robotDetails[props.robotId].latitudeSlam
            : robotDetails[props.robotId].latitude,
        ],
        zoom: zoom,
      });
      newMapRef.current = newMap


      // Marker type for with/without heading
      robotMarkerDiv.style.backgroundImage =
        !!Number(process.env.REACT_APP_HEADING) &&
        robotDetails[props.robotId].headingSlam
          ? "url(/assets/images/robot-icon-2.svg)"
          : "url(/assets/images/robot-icon-pickup_1.svg)";

      let newRobotMarker;

      if (
        robotDetails[props.robotId].headingSlam &&
        !!Number(process.env.REACT_APP_HEADING)
      ) {
        // initialize robot marker
        newRobotMarker = new mapboxgl.Marker(robotMarkerDiv)
          .setLngLat([
            slamPosition
              ? robotDetails[props.robotId].longitudeSlam
              : robotDetails[props.robotId].longitude,
            slamPosition
              ? robotDetails[props.robotId].latitudeSlam
              : robotDetails[props.robotId].latitude,
          ])
          .setRotation(parseFloat(robotDetails[props.robotId].headingSlam))
          .setRotationAlignment("map")
          .addTo(newMap);

        // newMap.setBearing(parseFloat(robotDetails[props.robotId].headingSlam));
      } else {
        newRobotMarker = new mapboxgl.Marker(robotMarkerDiv)
          .setLngLat([
            slamPosition
              ? robotDetails[props.robotId].longitudeSlam
              : robotDetails[props.robotId].longitude,
            slamPosition
              ? robotDetails[props.robotId].latitudeSlam
              : robotDetails[props.robotId].latitude,
          ])
          .addTo(newMap);
      }

      // initialize destination marker
      const newDestinationMarker = new mapboxgl.Marker(destinationMarkerDiv)
        .setLngLat([0, 0])
        .addTo(newMap);
      setDestinationMarker(newDestinationMarker);
      destinationMarkerForBound = [];

      newMap.on("wheel", (e) => {
        setUserControllingMap(true);
      });

      newMap.on("drag", (e) => {
        setUserControllingMap(true);
      });

      // initialize nogozone
      newMap.on("load", () => {
        // Add a data source containing GeoJSON data.
        newMap.addSource("nogozone", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",
              // These coordinates outline Maine.
              coordinates: [nogozonescoordinates],
            },
          },
        });

        newMap.addLayer({
          id: "nogozoneoutline",
          type: "line",
          source: "nogozone",
          layout: {},
          paint: {
            "line-color": "#f00",
            "line-width": 3,
          },
        });
      });

      // initialize geofencezone
      newMap.on("load", () => {
        newMap.addSource("geofence", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",
              // These coordinates outline Maine.
              coordinates: [geofencecoordinates],
            },
          },
        });
        newMap.addLayer({
          id: "geofenceoutline",
          type: "line",
          source: "geofence",
          layout: {},
          paint: {
            "line-color": "#f00",
            "line-width": 3,
          },
        });
      });

      // initialize fleetzones
      let newpolygonarray = [];
      let offset = 0.00003;

      props.fleetzones.map((polygon, index) => {
        newpolygonarray.push(
          polygon.map((data) => [data.lng, data.lat + offset])
        );

        newMap.on("load", () => {
          // Add a data source containing GeoJSON data.
          newMap.addSource("fleetzone-" + index, {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "Polygon",
                // These coordinates outline Maine.
                coordinates: [newpolygonarray[index]],
              },
            },
          });
          newMap.addLayer({
            id: "fleetzoneoutline-" + index,
            type: "fill",
            source: "fleetzone-" + index,
            layout: {},
            paint: {
              "fill-color": "#7859bc",
              "fill-opacity": 0.75,
            },
          });
        });

        return newpolygonarray;
      });

      // Add zoom and rotation controls to the map.
      newMap.addControl(new mapboxgl.NavigationControl());

      newMap.on("move", () => {
        setZoom(newMap.getZoom().toFixed(2));
      });

      setPolygonarray(newpolygonarray);
      setRobotMarker(newRobotMarker);
      setMap(newMap);

      mapRef.current = newMap;
    };
    if (!newMapRef.current) {
      initializeMap();
    }
    return () => {};
  }, [map, props.nogozones, props.fleetzones, props.geofencezone, setMap,props.waypoint,props.waypointlist]);
  
useEffect(()=>{

  let clickedMarker = null;
  
  if(props.waypointlist && newMapRef.current && props.waypoint){
    const newMap = newMapRef.current;
    // props.waypointlist?.map((item, index) => {
    //   const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    //   svgElement.setAttribute('width', '35');
    //   svgElement.setAttribute('height', '35');
    //   svgElement.setAttribute('viewBox', '0 0 36 36');   
    //   // Append the SVG code to the SVG element
    //   svgElement.innerHTML = `
    //   <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    //   <g id="dropoff" transform="translate(-5.802 -4.943)">
    //     <circle id="Ellipse_41" data-name="Ellipse 41" cx="18" cy="18" r="18" transform="translate(5.802 4.943)" fill="#fff"/>
    //     <circle id="Ellipse_42" data-name="Ellipse 42" cx="15" cy="15" r="15" transform="translate(8.802 7.943)" fill="#00c962" opacity="0.15"/>
    //     <g id="Group_182" data-name="Group 182" transform="translate(16.657 13.73)">
    //       <path id="Path_252" data-name="Path 252" d="M133.329,103.9a7.166,7.166,0,0,0-7.129,7.183c0,3.148,4.3,8.586,6.134,10.775a1.306,1.306,0,0,0,1,.452,1.337,1.337,0,0,0,1-.452c1.846-2.189,6.134-7.627,6.134-10.775A7.166,7.166,0,0,0,133.329,103.9Zm0,10.368a3.89,3.89,0,1,1,3.89-3.89A3.9,3.9,0,0,1,133.329,114.268Zm2.6-3.89a2.6,2.6,0,1,1-2.6-2.6A2.591,2.591,0,0,1,135.926,110.378Z" transform="translate(-126.2 -103.9)" fill=${getRandomColor()} fill-rule="evenodd"/>
    //     </g>
    //   </g>
    // </svg>      
    //   `;
    //   // Create a marker with the SVG element as the marker's element
    //   const newMarker = new mapboxgl.Marker({
    //     element: svgElement,
    //   })
    //     .setLngLat([parseFloat(item.lon), parseFloat(item.lat)])
    //     .addTo(newMap)
    //   return newMarker;
    // });
  newMap.on('click', (e) => {
    const { lng, lat } = e.lngLat;
    dispatch(waypointlatitude(lat));
    dispatch(waypointlongitude(lng));
    // Clear the previously clicked marker if it exists
    if (clickedMarker) {
      clickedMarker.remove();
    }
    // Create a new marker at the clicked location
      clickedMarker = new mapboxgl.Marker({ draggable: true }) // Set draggable to true
      .setLngLat([lng, lat])
      .addTo(newMap)

    // Create a popup to display the coordinates
    const popup = new mapboxgl.Popup()
      .setLngLat([lng, lat])
      .addTo(newMap);

    // Update marker's position and dispatch lat/lon on dragend
    clickedMarker.on('dragend', (e) => {
      const newLngLat = e.target.getLngLat();
      const newLat = newLngLat.lat;
      const newLng = newLngLat.lng;

      // Dispatch the new lat and lon values
      dispatch(waypointlatitude(newLat));
      dispatch(waypointlongitude(newLng));

      popup.setLngLat(newLngLat); // Update popup's position
    });

    // Close the popup after a delay (e.g., 5 seconds)
    setTimeout(() => {
      popup.remove();
    }, 10000); // Adjust the delay as needed
  });
  }
  else if(!props.waypoint) {
    // Remove the event listener and clear the clicked marker
    if (clickedMarker) {
      clickedMarker.remove();
    }
  }
  
} ,[props.waypointlist,props.waypointedit,props.waypoint ,dispatch])

useEffect(()=>{
  let indexmain=0
  indexmain = indexmain+1
    if(props.waypoint){
      const mySource = newMapRef.current.getSource("waypoint-0");
      newMapRef.current &&
        mySource &&
        props.waypointlist.map((item,index)=>{
          indexmain = indexmain+1
          let newsource = newMapRef.current.getSource(`waypoint-${index}`) 
          newsource.setData({
            type: "FeatureCollection",
            name: `waypoint-${index}`,
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "Point",
                  coordinates: [parseFloat(item.lon), parseFloat(item.lat)],
                },
              },
            ],
          });
       
          return null
        })
  
        !newMapRef.current.hasImage("waypoint-marker-0") &&
        waypointimage.map((item, index)=>{
          newMapRef.current.loadImage(
            item,
            (error, image) => {
              if (error) throw error;
              newMapRef.current.addImage(`waypoint-marker-${index}`, image);
            }
          );
           return null
        })
          
          indexmain = indexmain+1 
      !newMapRef.current.getSource("waypoint-0") &&
       props.waypointlist.map((data, index) => {
        indexmain = indexmain+1
        newMapRef.current.addSource(`waypoint-${index}`, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            name: `waypoint-${index}`,
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [parseFloat(data.lon), parseFloat(data.lat)],
                },
              },
            ],
          },
        });
    
        return null;
      });
      !newMapRef.current.getLayer("waypointlayer-0") &&
         props.waypointlist.map((data, index) => {
          newMapRef.current.addLayer({
                  id: `waypointlayer-${index}`,
                  type: "symbol",
                  source: `waypoint-${index}`,
                  layout: {
                    "icon-image": `waypoint-marker-${index}`,
                    // "icon-size": 0.1,
                    "icon-allow-overlap": true,
                    "text-allow-overlap": true,
                    "text-ignore-placement": true,
                    "icon-ignore-placement": true,
                  },
                  paint: {
                    "icon-opacity": 1,
                  },
                  filter: ["==", "$type", "Point"],
                });
      
              });
    }
    else if(!props.waypoint && props.waypointlist){
      // Remove sources and layers
      for (let index = 0; index < props?.waypointlist?.length; index++) {
        const sourceId = `waypoint-${index}`;
        const layerId = `waypointlayer-${index}`;
    
        if (newMapRef.current.getSource(sourceId)) {
          newMapRef.current.removeSource(sourceId);
        }
    
        if (newMapRef.current.getLayer(layerId)) {
          newMapRef.current.removeLayer(layerId);
        }
      }
    
      // Also remove the custom image if it was added
      if (newMapRef.current.hasImage("waypoint-marker")) {
        newMapRef.current.removeImage("waypoint-marker");
      }
    }

 
},[props.waypointlist,props.waypoint])

  useEffect(() => {
    if (
      (robotmsgData && robotmsgData.length > 0 && !robotmsgData.find((robotData) => robotData.location === "zaragoza")) ||
      !robotmsgData
    ) {
      getFleetListUser(
        localStorage.getItem("useremail"),
        localStorage.getItem("token")
      ).then((res) => {
        dispatch(robotmsgAction(res.data.fleet));
      });
    }

    if (map && map.isStyleLoaded()) {
      if (
        robotmsgData &&
        robotmsgData.find(
          (robotData) => robotData.fleetName === "Peoria_Riverfront"
        )
      ) {
        !map.hasImage("custom-marker") &&
          map.loadImage(
            "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
            (error, image) => {
              if (error) throw error;
              map.addImage("custom-marker", image);
            }
          );
        // Add a GeoJSON source with 2 points

        !map.getSource("riverFront") &&
          map.addSource("riverFront", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              name: "riverFronts",
              features: [
                {
                  type: "Feature",
                  properties: { Name: "Point 1" },
                  geometry: {
                    type: "Point",
                    coordinates: [-89.591121, 40.68748],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Point 2" },
                  geometry: {
                    type: "Point",
                    coordinates: [-89.588448, 40.688114],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Point 3" },
                  geometry: {
                    type: "Point",
                    coordinates: [-89.587556, 40.688682],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Point 4" },
                  geometry: {
                    type: "Point",
                    coordinates: [-89.586918, 40.689669],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Point 5" },
                  geometry: {
                    type: "Point",
                    coordinates: [-89.589788, 40.688297],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 1" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5924303, 40.6866848],
                        [-89.5923981, 40.6866523],
                        [-89.5911804, 40.6874068],
                        [-89.5912179, 40.6874475],
                        [-89.5924303, 40.6866848],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 2" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5912179, 40.6874475],
                        [-89.5912558, 40.6874303],
                        [-89.5912102, 40.6873958],
                        [-89.5900327, 40.6881117],
                        [-89.5900703, 40.6881503],
                        [-89.5912179, 40.6874475],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 3" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5900969, 40.6881302],
                        [-89.5899896, 40.6880204],
                        [-89.5899668, 40.6880072],
                        [-89.589948, 40.6879899],
                        [-89.5896838, 40.6877347],
                        [-89.5896731, 40.6877133],
                        [-89.5896597, 40.6876838],
                        [-89.5896355, 40.6876594],
                        [-89.5896262, 40.6876442],
                        [-89.5895819, 40.6876584],
                        [-89.5896114, 40.6876787],
                        [-89.5896235, 40.687693],
                        [-89.5896195, 40.6877123],
                        [-89.5896141, 40.6877235],
                        [-89.5900703, 40.6881503],
                        [-89.5900969, 40.6881302],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 5" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5895815, 40.6874292],
                        [-89.5895567, 40.6874078],
                        [-89.5891593, 40.6876462],
                        [-89.5889125, 40.6877967],
                        [-89.588529, 40.6880347],
                        [-89.5882715, 40.6881831],
                        [-89.5879094, 40.6884211],
                        [-89.5876519, 40.6885757],
                        [-89.5873676, 40.6887506],
                        [-89.5871718, 40.6888604],
                        [-89.5867775, 40.6891106],
                        [-89.5868312, 40.6891553],
                        [-89.5870216, 40.6890394],
                        [-89.5872442, 40.6889051],
                        [-89.5875178, 40.6887445],
                        [-89.5877136, 40.6886082],
                        [-89.5879684, 40.6884597],
                        [-89.588191, 40.6883092],
                        [-89.5884968, 40.6881181],
                        [-89.5885183, 40.6880937],
                        [-89.5885639, 40.6880652],
                        [-89.5886041, 40.6880347],
                        [-89.5895815, 40.6874292],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 6" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.587067, 40.6893182],
                        [-89.5869892, 40.6892348],
                        [-89.587008, 40.6890538],
                        [-89.586953, 40.6890029],
                        [-89.5867827, 40.6891168],
                        [-89.5870147, 40.6893487],
                        [-89.587067, 40.6893182],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 10" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5900618, 40.6880981],
                        [-89.5898727, 40.6882253],
                        [-89.5899009, 40.6882507],
                        [-89.5900969, 40.6881302],
                        [-89.5900618, 40.6880981],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 11" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5898969, 40.6882131],
                        [-89.5897628, 40.6882924],
                        [-89.5897721, 40.6883066],
                        [-89.5896233, 40.6883961],
                        [-89.5896125, 40.688388],
                        [-89.5895401, 40.6884368],
                        [-89.5894248, 40.6884978],
                        [-89.5892437, 40.6886117],
                        [-89.5890788, 40.6887134],
                        [-89.5889849, 40.6887673],
                        [-89.5889004, 40.6888212],
                        [-89.5887811, 40.6888883],
                        [-89.5888092, 40.6889127],
                        [-89.5889058, 40.6888598],
                        [-89.5890386, 40.6887774],
                        [-89.5891351, 40.6887144],
                        [-89.5892223, 40.6886625],
                        [-89.5893255, 40.6886076],
                        [-89.5894275, 40.6885375],
                        [-89.5895321, 40.6884724],
                        [-89.5897641, 40.6883442],
                        [-89.5898633, 40.6882731],
                        [-89.589921, 40.6882364],
                        [-89.5898969, 40.6882131],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 12" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5888323, 40.6889005],
                        [-89.5888068, 40.6888746],
                        [-89.5886392, 40.6889738],
                        [-89.5886713, 40.6889982],
                        [-89.5887424, 40.688956],
                        [-89.5888323, 40.6889005],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 13" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5886498, 40.6889684],
                        [-89.5885157, 40.6890457],
                        [-89.5884245, 40.6891037],
                        [-89.5883199, 40.6891637],
                        [-89.5882206, 40.6892308],
                        [-89.5881066, 40.689304],
                        [-89.587947, 40.6893975],
                        [-89.5877901, 40.6894931],
                        [-89.5877164, 40.6895399],
                        [-89.5875903, 40.6896172],
                        [-89.5874763, 40.6896894],
                        [-89.5875031, 40.6897118],
                        [-89.5876225, 40.6896396],
                        [-89.5876976, 40.6895887],
                        [-89.5878169, 40.6895186],
                        [-89.5879108, 40.6894616],
                        [-89.5880114, 40.6893996],
                        [-89.5880865, 40.6893548],
                        [-89.5882233, 40.6892704],
                        [-89.5883816, 40.6891799],
                        [-89.5885116, 40.6891006],
                        [-89.5886779, 40.6889918],
                        [-89.5886498, 40.6889684],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 14" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5875176, 40.6896992],
                        [-89.5874935, 40.6896788],
                        [-89.5872091, 40.6898517],
                        [-89.5872386, 40.6898731],
                        [-89.5875176, 40.6896992],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 12" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.587092, 40.6893405],
                        [-89.5869096, 40.6891188],
                        [-89.5866173, 40.6892775],
                        [-89.5866012, 40.6893649],
                        [-89.586742, 40.6895347],
                        [-89.5869981, 40.6898052],
                        [-89.5871436, 40.6898149],
                        [-89.5872261, 40.6898835],
                        [-89.5873327, 40.6898205],
                        [-89.5872932, 40.6897818],
                        [-89.5872301, 40.6897727],
                        [-89.587033, 40.6897518],
                        [-89.5868144, 40.6895103],
                        [-89.587092, 40.6893405],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 13" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5896355, 40.6876594],
                        [-89.5895685, 40.6875826],
                        [-89.5895564, 40.6875791],
                        [-89.589537, 40.6875618],
                        [-89.5895591, 40.6875323],
                        [-89.5895745, 40.6874962],
                        [-89.5895506, 40.6874337],
                        [-89.5895237, 40.6874398],
                        [-89.5895103, 40.6874632],
                        [-89.5895398, 40.6874805],
                        [-89.5895323, 40.6875099],
                        [-89.5895142, 40.6875308],
                        [-89.5895021, 40.6875694],
                        [-89.5895155, 40.6875882],
                        [-89.5895229, 40.6876086],
                        [-89.589596, 40.6876675],
                        [-89.5896355, 40.6876594],
                      ],
                    ],
                  },
                },
              ],
            },
          });

        !map.getLayer("RiverfrontLineLayer") &&
          map.addLayer({
            id: "RiverfrontLineLayer",
            type: "fill",
            source: "riverFront",
            paint: {
              "fill-color": "#7859bc",
              "fill-opacity": 1,
            },
            filter: ["==", "$type", "Polygon"],
          });
        !map.getLayer("Riverfront") &&
          map.addLayer({
            id: "Riverfront",
            type: "symbol",
            source: "riverFront",
            layout: {
              "icon-image": "custom-marker",
              "icon-size": 0.65,
            },
            filter: ["==", "$type", "Point"],
          });
      }

      if (
        robotmsgData &&
        robotmsgData.find((robotData) => robotData.location === "zaragoza")
      ) {
        !map.getLayer("zaragoza-okoutline") &&
          map.addLayer({
            id: "zaragoza-okoutline",
            type: "line",
            source: {
              type: "vector",
              url: "mapbox://mrlakshyagupta.cliockddp17ir2onppxm67ul4-2322c",
            },
            "source-layer": "Zaragoza-OK",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#0000ff",
              "line-width": 2,
            },
          });
        !map.getLayer("zaragoza-goodoutline") &&
          map.addLayer({
            id: "zaragoza-goodoutline",
            type: "line",
            source: {
              type: "vector",
              url: "mapbox://mrlakshyagupta.cliockw3y0x522ophzjv42pw5-05s1c",
            },
            "source-layer": "Zaragoza-GOOD",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#027148",
              "line-width": 2,
            },
          });
        !map.getLayer("zaragoza-difficultoutline") &&
          map.addLayer({
            id: "zaragoza-difficultoutline",
            type: "line",
            source: {
              type: "vector",
              url: "mapbox://mrlakshyagupta.cliocljvn0yvs2cphplde8mwp-65qz7",
            },
            "source-layer": "Zaragoza-DIFFICULT",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#8B0000",
              "line-width": 2,
            },
          });
        !map.getLayer("zaragoza-nooutline") &&
          map.addLayer({
            id: "zaragoza-nooutline",
            type: "line",
            source: {
              type: "vector",
              url: "mapbox://mrlakshyagupta.cliocqzf906lb2bo9xa6o69r5-5ubuf",
            },
            "source-layer": "Zaragoza-NO",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#FF0000",
              "line-width": 2,
            },
          });
      }

      if (
        !map.getSource("robotPath") &&
        ((robotDetails[props.robotId].hasOwnProperty("path") &&
          robotDetails[props.robotId].path.length > 0) ||
          (robotDetails[props.robotId].hasOwnProperty("robotCommandList") &&
            robotDetails[props.robotId].robotCommandList.length > 0 &&
            robotDetails[props.robotId].robotCommandList
            .find((x) => x.actionPriority === 1)&&
            robotDetails[props.robotId].robotCommandList.find(
              (x) => x.actionPriority === 1
            ).actionType.includes("MOVE")))
      ) {
        let newrobotPath = [];

        newrobotPath =
          robotDetails[props.robotId].path &&
          robotDetails[props.robotId].path.map((pathDirection) => [
            pathDirection.lng,
            pathDirection.lat,
          ]);

        map.addSource("robotPath", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: newrobotPath,
            },
          },
        });

        map.addLayer({
          id: "path",
          type: "line",
          source: "robotPath",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#0f0",
            "line-width": 5,
          },
        });
      }

      if (
        !userControllingMap &&
        robotDetails[props.robotId].hasOwnProperty("path") &&
        robotDetails[props.robotId].path.length > 0 &&
        robotDetails[props.robotId].hasOwnProperty("robotCommandList") &&
        robotDetails[props.robotId].robotCommandList.length > 0 &&
        robotDetails[props.robotId].robotCommandList
          .find((x) => x.actionPriority === 1)&&
        robotDetails[props.robotId].robotCommandList
          .find((x) => x.actionPriority === 1)
          .actionType.includes("MOVE") 
      ) {
        let newrobotPath = [];
        newrobotPath =
          robotDetails[props.robotId].path &&
          robotDetails[props.robotId].path.map((pathDirection) => [
            pathDirection.lng,
            pathDirection.lat,
          ]);

        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(
          newrobotPath[0],
          newrobotPath[0]
        );

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of newrobotPath) {
          bounds.extend(coord);
        }

        map.fitBounds(bounds, {
          padding: 20,
        });
      } else if (!userControllingMap && nogozonescoordinates.length > 0) {
        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(
          nogozonescoordinates[0],
          nogozonescoordinates[0]
        );

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of nogozonescoordinates) {
          bounds.extend(coord);
        }

        map.fitBounds(bounds, {
          padding: 20,
        });
      } else if (!userControllingMap && geofencecoordinates.length > 0) {
        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(
          geofencecoordinates[0],
          geofencecoordinates[0]
        );

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of geofencecoordinates) {
          bounds.extend(coord);
        }

        map.fitBounds(bounds, {
          padding: 20,
        });
      }
    }

    // move robot marker
    if (robotMarker) {
      if (
        !!Number(process.env.REACT_APP_HEADING) &&
        (robotDetails[props.robotId].headingSlam ||
          robotDetails[props.robotId].heading)
      ) {
        robotMarker
          .setLngLat([
            slamPosition
              ? parseFloat(robotDetails[props.robotId].longitudeSlam)
              : parseFloat(robotDetails[props.robotId].longitude),
            slamPosition
              ? parseFloat(robotDetails[props.robotId].latitudeSlam)
              : parseFloat(robotDetails[props.robotId].latitude),
          ])
          .setRotation(
            parseFloat(
              robotDetails[props.robotId].headingSlam
                ? robotDetails[props.robotId].headingSlam
                : robotDetails[props.robotId].heading
            )
          )
          .setRotationAlignment("map");

        // map.setBearing(parseFloat(robotDetails[props.robotId].headingSlam));
      } else {
        robotMarker.setLngLat([
          slamPosition
            ? parseFloat(robotDetails[props.robotId].longitudeSlam)
            : parseFloat(robotDetails[props.robotId].longitude),
          slamPosition
            ? parseFloat(robotDetails[props.robotId].latitudeSlam)
            : parseFloat(robotDetails[props.robotId].latitude),
        ]);
      }
    }

    // move destination marker
    if (
      destinationMarker &&
      robotDetails[props.robotId].hasOwnProperty("robotCommandList") &&
      robotDetails[props.robotId].robotCommandList.length > 0 &&
      robotDetails[props.robotId].robotCommandList
      .find((x) => x.actionPriority === 1)&&
      robotDetails[props.robotId].robotCommandList.find(
        (x) => x.actionPriority === 1
      )
    ) {
      let destinationlng = robotDetails[props.robotId].robotCommandList.find(
        (x) => x.actionPriority === 1
      ).longitude;
      let destinationlat = robotDetails[props.robotId].robotCommandList.find(
        (x) => x.actionPriority === 1
      ).latitude;

      if (destinationlng && destinationlat) {
        // destinationMarkerDiv.style.visibility = "visible";
        destinationMarker.setLngLat([destinationlng, destinationlat]);

        if (!userControllingMap) {
          const mySource = map.getSource("robotPath");
          if (map && mySource) {
            // Create a 'LngLatBounds' with both corners at the first coordinate.
            const bounds = new mapboxgl.LngLatBounds(
              mySource._options.data.geometry.coordinates[0],
              mySource._options.data.geometry.coordinates[0]
            );

            // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
            for (const coord of map &&
              mySource &&
              mySource._options.data.geometry.coordinates) {
              bounds.extend(coord);
            }

            bounds.extend([
              slamPosition
                ? parseFloat(robotDetails[props.robotId].longitudeSlam)
                : parseFloat(robotDetails[props.robotId].longitude),
              slamPosition
                ? parseFloat(robotDetails[props.robotId].latitudeSlam)
                : parseFloat(robotDetails[props.robotId].latitude),
            ]);

            bounds.extend([destinationlng, destinationlat]);

            destinationMarkerForBound = [destinationlng, destinationlat];

            map?.fitBounds(bounds, {
              padding: 100,
            });
          }
        }
      }
    }

    // move map
    let offset = 0.00003;
    setnogozonescoordinates(
      props.nogozones.map((data) => [data.lng, data.lat + offset])
    );
    setgeofencecoordinates(
      props.geofencezone.map((data) => [data.lng, data.lat + offset])
    );

    let newpolygonarray = [];

    props.fleetzones.map((polygon, index) => {
      newpolygonarray.push(polygon.map((data) => [data.lng, data.lat]));
      return newpolygonarray;
    });
    setPolygonarray(newpolygonarray);

    // Add a data source containing GeoJSON data.
    if (robotDetails[props.robotId] && robotDetails[props.robotId].hasOwnProperty("path")) {
      updateRobotpath();
    }
  }, [
    robotDetails[props.robotId].longitude,
    robotDetails[props.robotId].latitude,
    robotMarker,
    destinationMarker,
    props.robotId,
    props.fleetzones,
    props.geofencezone,
    props.nogozones,
    props.robotpathcheck,
    dispatch,
    robotDirections,
    robotDetails,
    robotDetails[props.robotId],
    robotDetails[props.robotId].path,
    props.waypoint,
    props.waypointlist,
    // robotPath
  ]);

  return (
    <>

      <div className="h-full"  style={{ height: "100%" }}> 
        <div  style={{
            backgroundColor: "rgba(35, 55, 75, 0.9)",
            color: "#fff",
            padding: "6px 12px",
            fontFamily: "monospace",
            zIndex: "1",
            position: "absolute",
            top: "0",
            left: "0",
            margin: "12px",
            borderRadius: "4px",
          }}  
          className="sidebar">
          Ottobot Position [
          {slamPosition
            ? robotDetails[props.robotId]?.latitudeSlam
            : robotDetails[props.robotId]?.latitude}
          ,
          {slamPosition
            ? robotDetails[props.robotId]?.longitudeSlam
            : robotDetails[props.robotId]?.longitude}
          ] | Zoom: {zoom}

        </div>

        {userControllingMap && (
          <div
          style={{ 
           cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            backgroundColor: "rgba(35,55,75,0.9)",
            color: "white",
            padding: "6px 12px",
            zIndex: "1",
            position: "absolute",
            bottom: "0",
            right: "0",
            margin: "12px",
            marginBottom: "16px",
            borderRadius: "4px",
            height: "30px"
          }}
            onClick={() => setUserControllingMap(false)}
          >
            <FiNavigation2 />
            <span className="text-sm">Re-centre</span>
          </div>
        )}
       {props.video &&<iframe className="Video_stream_iframe" title="dsv"src={robotDetails[props.robotId]?.viewerurl} allowFullScreen ></iframe>}
        <div
          id="map-container-id"
          // ref={mapContainer}
          className="h-full map-container"
          style={{height:'100%'}}
           />
      </div>
    </>
  );
};
export default React.memo(MapboxMain);
