import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/actions/user';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 430,
  bgcolor: '#282F42',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px'
};

function LogoutModal(props) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = (reason) => {
    if (reason && reason == "backdropClick")
      return;
    setOpen(false)
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = localStorage.getItem('token')
  const handlelogoutbtn = () => {
    localStorage.removeItem('useremail')
    localStorage.removeItem('password')
    localStorage.removeItem('token')
    localStorage.removeItem('fleetID')
    localStorage.removeItem('Isloggedin', "LoginFalse")
    localStorage.removeItem('mapid')
    localStorage.removeItem('Distance')
    localStorage.removeItem('robotId')
    localStorage.removeItem('role')
    localStorage.removeItem('firstname')
    localStorage.removeItem('lastname')
    dispatch(logout())
    navigate('/')
  }
  return (
    <>
      {props.header ? <Button className="btn-dashboard-red-color cancel-order" onClick={handleOpen} style={{ color: 'white', padding: "0px", textTransform: "capitalize", fontFamily: 'Poppins', fontSize: '15px',padding:'' }}>
        {props.dashboard ? <PowerSettingsNewIcon className="Heade_Power_button" /> : <PowerSettingsNewIcon className="Heade_Power_button" />}
      </Button>
        : <Button className="cancel-order logout-button-footer robot_logoutbutton" onClick={handleOpen} style={{ color: 'white', padding: "0px", textTransform: "capitalize", fontFamily: 'Poppins', fontSize: '15px' ,padding:''}}>
          {props.dashboard ? <PowerSettingsNewIcon className="Heade_Power_button" /> : <PowerSettingsNewIcon className="Heade_Power_button" />}
        </Button>}
        {/* {props.header ? 
        <Button className="btn-dashboard-red-color cancel-order" onClick={handleOpen} style={{ color: 'white', padding: "0px", textTransform: "capitalize", fontFamily: 'Poppins', fontSize: '15px' }}>
             {props.dashboard ?'Logout' :'Logout'}
        </Button>
        : <Button className="cancel-order logout-button-footer robot_logoutbutton" onClick={handleOpen} style={{ color: 'white', padding: "0px", textTransform: "capitalize", fontFamily: 'Poppins', fontSize: '15px'}}>
          {props.dashboard ?'Logout' :'Logout'}
        </Button>} */}
      <Modal
        open={open}
        // BackdropProps={false}
        onHide={handleClose}
        // onClose={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >
        <Box sx={style} >
          <div className='Cancel-order-Modal-Mainwrapper'>
            {/* <img src="/assets/images/cancel-order.png"/> */}
            <p> Are you sure want to logout ?</p>
            <div className="Modal-button-Main-wrapper">
              <Button className="Modal-button-Main-btn yes-red-active" onClick={() => {
                handleClose()
                handlelogoutbtn()
              }}> Yes</Button>
              <Button className="Modal-button-Main-btn color-white" onClick={handleClose}> No</Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
export default React.memo(LogoutModal)