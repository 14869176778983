export const MapData=[
    {
     maptype:'Base Map Image',
     fileName:'xyz.jpg',
    },
    {
        maptype:'Floor Plan Image',
        fileName:'xyz.jpg',
       },
       {
        maptype:'Annoted Image',
        fileName:'xyz.jpg',
       },
       {
        maptype:'No GoZone Image',
        fileName:'xyz.jpg',
       },    
]
export const pickuplocations=[
    {
        locationName:'TRIPADVISOR',
        position:'77.7784,3.684,1.619',
        status:'off',  
    },
    {
        locationName:'TRIPADVISOR',
        position:'77.7784,3.684,1.619',
        status:'off',  
    }
]
export const dropofflocations=[
    {
        locationName:'B1',
        position:'77.7784,3.684,1.619',
        status:'off', 
        wandor:'on' 
    },
    {
        locationName:'B2',
        position:'77.7784,3.684,1.619',
        status:'off', 
        wandor:'off' 
    },
    {
        locationName:'B3',
        position:'77.7784,3.684,1.619',
        status:'off',
        wandor:'off'  
    },
    {
        locationName:'B3',
        position:'77.7784,3.684,1.619',
        status:'off',
        wandor:'off'  
    }
]
export const homelocation=[
    {
      locationName:'HOME1',
      position:'88.852,-11.202,-1.476',
      status:'off',
      assignrobot:[ 'ROVER003', 'ROVER003'], 
    },
    {
        locationName:'HOME1',
        position:'88.852,-11.202,-1.476',
        status:'off',
        assignrobot:[ 'ROVER003', 'ROVER003'], 
    },
    {
        locationName:'HOME1',
        position:'88.852,-11.202,-1.476',
        status:'off',
        assignrobot:[ 'ROVER003', 'ROVER003'], 
    },
    {
    locationName:'HOME1',
    position:'88.852,-11.202,-1.476',
    status:'off',
    assignrobot:[ 'ROVER003', 'ROVER003'], 
   }
]
export const waypointData=[
    {   name:'Zaragoza',
        latitude:'26.311',
        longitude:'50.133'
    },
    {   name:'Neom',
        latitude:'26.322',
        longitude:'50.444'
    },
    {   name:'Peoria',
        latitude:'26.333',
        longitude:'50.543'
    },
    {   name:'Pittsburg',
        latitude:'26.323',
        longitude:'50.674'
    },

]
export const waypointimage=[ 
    '/assets/images/dropoff-1.png',
    '/assets/images/dropoff-2.png',
    '/assets/images/dropoff-7.png',   
    '/assets/images/dropoff-4.png',
    '/assets/images/dropoff-5.png',
    '/assets/images/dropoff-6.png',
    '/assets/images/dropoff-3.png',
]