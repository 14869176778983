import { io } from "socket.io-client";
import { robotConstants } from "./Contants";

const userlogindata={}
export const UserloginData=(state=userlogindata, action)=>{
    switch(action.type){
        case 'USER_LOGIN': 
        return{
            ...state,
            userlogindata:action.payload
        }   
        default:
            return state;    
    }

}

const dashboardopen=true
export const dashboardopenReducer=(state=dashboardopen, action)=>{
    switch(action.type){
        case 'OPEN': 
        return{
            ...state,
            userlogindata:true
        }   
        case 'CLOSE': 
        return{
            ...state,
            userlogindata:false
        }
        default:
            return state;    
    }

}
const fleetId=''
export const fleetIdreducer=(state=fleetId, action)=>{
    switch(action.type){
        case 'FETCH_FLEET_ID': 
        return{
            ...state,
            fleetId:action.payload
        }    
        default:
            return state;    
    }
}
const robotData={}
export const robotdatareducer=(state=robotData, action)=>{
    switch(action.type){
        case 'FETCH_ROBOT_DATA': 
        return{
            ...state,
            robotData:action.payload
        }    
        default:
            return state;    
    }
}
const robotmsgData=[]
export const robotMessageReducer=(state=robotmsgData, action)=>{
    switch(action.type){
        case 'FETCH_ROBOTMSG_DATA': 
        return{
            ...state,
            robotmsgData:action.payload
        }    
        default:
            return state;    
    }
}
const robotID=[]
export const robotidReducer=(state=robotID, action)=>{
    // console.log('RobotState', state,action.payload )
    switch(action.type){
        case 'GET_ROBOT_ID': 
        return{
            ...state,
            robotID:action.payload
        }    
        default:
            return state;    
    }
}

const robotlist=[]
export const robotlistReducer=(state=robotlist, action)=>{
    // console.log('RobotState', state,action.payload )
    switch(action.type){
        case 'FETCH_ROBOT_LIST_DATA': 
        return{
            ...state,
            robotlist:action.payload
        }    
        default:
            return state;    
    }
}

const sshendpoint=""
export const sshendpointReducer=(state=sshendpoint,action)=>{
    switch(action.type){
        case 'FETCH_SSH_ENDPOINT': 
        return{
            ...state,
            sshendpoint:action.payload
        }    
        default:
            return state;    
    }
}
const latitude=""
export const fleetlatitudeReducer=(state=latitude,action)=>{
    switch(action.type){
        case 'FETCH_FLEET_LATITUDE': 
        return{
            ...state,
            latitude:action.payload
        }    
        default:
            return state;    
    }
}
const longitude=""
export const fleetlongitudeReducer=(state=longitude,action)=>{
    switch(action.type){
        case 'FETCH__FLEET_LONGITUDE': 
        return{
            ...state,
            longitude:action.payload
        }    
        default:
            return state;    
    }
}
const robotStatusData={}
export const robotStatusDataReducer=(state=robotStatusData,action)=>{
    switch(action.type){
        case 'FETCH_ROBOT_STATUS_DATA': 
        return {
            ...state,
            robotStatusData:action.payload
        }  
        default:
            return state;    
    }
}

const robotCommandListData={}
export const robotcommmandlistReducer=(state=robotCommandListData,action)=>{
    switch(action.type){
        case 'FETCH_ROBOT_COMMANDLIST_DATA': 
        return {
            ...state,
            robotCommandListData:action.payload
        }  
        default:
            return state;    
    }
}
const robotStatusFleetId=[]
export const robotStatusFleetIdReducer=(state=robotStatusFleetId,action)=>{
    switch(action.type){
        case 'FETCH_ROBOT_STATUSFLEET_DATA': 
        return {
            ...state,
            robotStatusFleetId:action.payload
        }  
        default:
            return state;    
    }
}
const fleetlistrobotdata=[]
export const fleetlistandrobotemailReducer=(state=fleetlistrobotdata,action)=>{
    switch(action.type){
        case 'FETCH_FLEET_ROBOT_EMAIL': 
        return {
            ...state,
            fleetlistrobotdata:action.payload
        }  
        default:
            return state;    
    }
}
// const socket = io("ws://localhost:9898",{ transports: ["polling"] });

export const websocketwsreducer=(state='',action)=>{
    switch(action.type){
        case 'FETCH_WEBSOCKET_CONNECTION': 
        return ''
        default:
            return '';    
    }
}

export const robotDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case robotConstants.ROBOT_DETAILS_REQUEST:
      case robotConstants.ROBOT_STATUS_REQUEST:
      case robotConstants.ROBOT_ACTION_REQUEST:
        return {
          ...state,
        };
  
      case robotConstants.ROBOT_DETAILS_SUCCESS:
      case robotConstants.ROBOT_STATUS_SUCCESS:
      case robotConstants.ROBOT_ACTION_SUCCESS:
        return {
          ...state,
          ...action.payload,
        };
  
      case robotConstants.ROBOT_DETAILS_FAIL:
      case robotConstants.ROBOT_STATUS_FAIL:
      case robotConstants.ROBOT_ACTION_FAIL:
        return {
          ...state,
          error: action.payload,
        };
  
      case robotConstants.CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  export const orderStatusReducer = (state = { orderStatus: "" }, action) => {
    switch (action.type) {
      case robotConstants.ORDER_DETAILS_REQUEST:
        return {
          ...state,
        };
  
      case robotConstants.ORDER_DETAILS_SUCCESS:
        return {
          ...state,
          orderStatus: action.payload,
        };
  
      case robotConstants.ORDER_DETAILS_FAIL:
        return {
          ...state,
          error: action.payload,
        };
  
      case robotConstants.CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  export const robotDirectionsReducer = (
    state = { robotDirections: {} },
    action
  ) => {
    switch (action.type) {
      case robotConstants.ROBOT_DIRECTIONS_REQUEST:
        return {
          ...state,
        };
  
      case robotConstants.ROBOT_DIRECTIONS_SUCCESS:
        return {
          ...state,
          robotDirections: action.payload,
        };
  
      case robotConstants.ROBOT_DIRECTIONS_FAIL:
        return {
          ...state,
          error: action.payload,
        };
  
      case robotConstants.CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  const currentDate = new Date();

  // Calculate the date 7 days before
  const sevenDaysBefore = new Date();
  sevenDaysBefore.setDate(currentDate.getDate() - 7);
export const  datefilterreducer = (state = { 
    startDate:sevenDaysBefore,
    endDate:new Date()
}, action) => {
    switch (action.type) {
        case 'START_DATE':
            return {
                ...state,
                startDate: action.payload,
            };
        case 'END_DATE':
         return {
              ...state,
              endDate: action.payload,
          };
           
        default:
            return state;
    }
};


export const waypointsreducer= (state={waypointlatitude:0 ,waypointlongitude:0 } , action)=>{
  switch(action.type){
    case "WAVE_LATITUDE":
       return{
          ...state,
          waypointlatitude :action.payload
       }
    case "WAVE_LONGITUDE":
        return{
           ...state,
           waypointlongitude :action.payload
        }  
     default:
      return state;    
  }
}
export const waypointcolorreducer = (state = {}, action) => {
  switch (action.type) {
    case "WAYPOINT_COLOR":
      return {
        ...state,
        waypointcolor: [...action.payload], // Create a new array from action.payload
      };

    default:
      return state;
  }
};